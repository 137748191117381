const audio = {
  music1: '/assets/audio/music1.mp3',
  music2: '/assets/audio/music2.mp3',
  music3: '/assets/audio/music3.mp3',
  music4: '/assets/audio/music4.mp3',
  music5: '/assets/audio/music5.mp3',
  music6: '/assets/audio/music6.mp3',
  music7: '/assets/audio/music7.mp3',
  music8: '/assets/audio/music8.mp3',
};

export default audio;
